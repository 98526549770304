import create from 'zustand';

interface CoverIntersectionState {
  // Get and set the state of logo intersecting with the project cover
  isIntersectingWithCover: boolean | undefined;
  setIntersectionState: (boolean: boolean) => void;
}

export const useCoverIntersactionStore = create<CoverIntersectionState>(
  (set) => ({
    isIntersectingWithCover: undefined,
    setIntersectionState: (boolean: boolean) =>
      set({ isIntersectingWithCover: boolean }),
  })
);
