import { Box } from '@/components/box';
import { Text } from '@/components/text';
import { Underline, thinnerUnderlineHoverStyles } from '@/components/underline';
import { css } from '@/stitches.config';
import Link from 'next/link';
import CookieConsent from 'react-cookie-consent';

export function CookiePopup() {
  return (
    <CookieConsent
      buttonText="Accept"
      buttonClasses={acceptButtonStyle()}
      declineButtonClasses={rejectButtonStyle()}
      containerClasses={containerStyle()}
      enableDeclineButton
      declineButtonText="Reject"
      disableStyles={true}
      debug={false}
    >
      <Box
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '$4',
        }}
      >
        <Text as="p" size={2} css={{ mb: 0 }}>
          We use cookies to offer you an optimal user experience and to monitor
          traffic.
        </Text>
        <Link href="/privacy-policy" passHref>
          <Text
            size={2}
            as="a"
            css={{
              display: 'inline-block',
              textDecoration: 'none',
              color: '$gray9',
              position: 'relative',
              mb: 0,
              overflow: 'hidden',

              '&:hover, &:active': {
                [`${Underline} > div`]: thinnerUnderlineHoverStyles,
              },
            }}
          >
            Find out more
            <Underline />
          </Text>
        </Link>
      </Box>
    </CookieConsent>
  );
}

const rejectButtonStyle = css({
  marginRight: '$5',
  capSize: 1,
  padding: '$3',
  color: '$foreground',
  opacity: 0.7,
  transition: '0.25s ease-in-out',

  '&:hover': {
    opacity: 1,
  },
});

export const acceptButtonStyle = css({
  capSize: 2,
  border: '1px solid $foreground',
  padding: '$3',
  color: '$foreground',
  transition: 'color 0.2s ease-in-out, background-color 0.2s ease-in-out',

  '&:hover': {
    color: '$background',
    backgroundColor: '$foreground',
  },
});

const containerStyle = css({
  display: 'flex',
  position: 'fixed',
  zIndex: 2,
  background: '$background',
  color: '$foreground',
  right: '$4',
  left: '$4',
  marginBottom: '$4',
  padding: '$4',
  flexWrap: 'wrap',
  gap: '$4',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  border: '1px solid $borderColor',

  '@bp3': {
    width: '20rem',
    height: '20rem',
    left: 'unset',
    padding: '$7',
  },
});
