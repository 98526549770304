import { groq } from 'next-sanity';
import { getData } from '../get-data';
import { z } from 'zod';
import { SanityImageCropSchema } from '@/lib/schemas/sanity-image-crop';
import { SanityImageHotspotSchema } from '@/lib/schemas/sanity-image-hotspot';
import { SanityReferenceSchema } from '@/lib/schemas/sanity-reference';

// Get all projects and their slugs for the work overview page
const getAllProjectsQuery = groq`
*[_type == "project" && isActive != false] {
    _id,
    title, subtitle, cover,
    "slug": slug.current,
    "toolkitItems": toolkitItems[] -> slug
}
`;

const PartialProjectsSchema = z.array(
  z.object({
    _id: z.string(),
    title: z.string(),
    subtitle: z.string(),
    cover: z.optional(
      z.object({
        asset: SanityReferenceSchema,
        crop: z.optional(SanityImageCropSchema),
        hotspot: z.optional(SanityImageHotspotSchema),
      })
    ),
    slug: z.string(),
    toolkitItems: z.union([
      z.array(z.object({ current: z.string() })),
      z.null(),
    ]),
  })
);

export type PartialProjects = z.infer<typeof PartialProjectsSchema>;

export const getAllProjects = async (
  preview: boolean
): Promise<z.infer<typeof PartialProjectsSchema>> => {
  return getData(getAllProjectsQuery, PartialProjectsSchema, preview);
};
